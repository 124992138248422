import React, {useContext, useState} from "react";
import classes from "./Login.module.scss";
import TextH2 from "../../components/ui/typographuck/textH2/TextH2";
import {Context} from "../../context/context";
import Button from "../../components/ui/buttons/button/Button";
import Container from "../../components/container/Container";
import TitleSmall from "../../components/titleSmall/TitleSmall";
import {InputOther} from "../../components/ui/input/Input-other";
import {HOST} from "../../constants";
import {useNavigate} from "react-router-dom";

const Login = () => {

  const navigate = useNavigate();

    const {setIsAuth} = useContext(Context)

    const [loginInput, setLoginInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');

    const login = async (event) => {
        event.preventDefault();
        const response = await fetch(`${HOST}/auth/login`, {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            login: loginInput,
            password: passwordInput
          })
        })
        const responseData = await response.json();

        if(responseData?.access_token) {
          setIsAuth(responseData.access_token);
          localStorage.setItem('access_token', responseData.access_token);
          navigate('/')
        }
    }

    return (
        <div className={classes.login}>
            <Container>
                <div className={classes.login__content}>
                    <div className={classes.login__title}>
                        <TextH2 children="Вход в сервис"/>
                    </div>
                    <div className={classes.form} >
                        <div className={classes.form__item}>
                            <TitleSmall children="Логин"/>
                            <InputOther type="text" value={loginInput} onInput={e => setLoginInput(e.target.value)}/>
                        </div>
                        <div className={classes.form__item}>
                            <TitleSmall children="Пароль"/>
                            <InputOther type="password"  value={passwordInput} onInput={e => setPasswordInput(e.target.value)}/>
                        </div>
                        <Button children="Войти" onClick={login}/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Login;