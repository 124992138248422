import React from "react";
import classes from "./TextH2.module.scss";

const TextH2 = ({children = '', ...props}) => {
    return (
        <h2 {...props} className={classes.textH2}>
            {children}
        </h2>
    );
};

export default TextH2;