import React, { useContext } from "react";
import classes from "./Modal.module.scss";
import Title from "../title/Title";
import { ModalContext } from "../../context/modal";


export const Modal = ({title='', ...props}) => {
  const {close} = useContext(ModalContext)
  const body = props.children
  return (
      <div className={classes.modal}>
        <div className={classes.modalBack} onClick={close}/>
        <div className={classes.modalWindow}>
          <div className={classes.modalWindow__title}>
            <Title children={title}/>
            <div className={classes.modalWindow__close}
                  onClick={close}>
              <svg viewBox="0 0 12 12" version="1.1"
                   xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="11"
                      x2="11" y2="1"
                      stroke="black"
                      strokeWidth="2"/>
                <line x1="1" y1="1"
                      x2="11" y2="11"
                      stroke="black"
                      strokeWidth="2"/>
              </svg>
            </div>
          </div>
          <div className={classes.modalWindow__body}>
            {body}
          </div>
        </div>
      </div>
        )
}