import React from 'react';
import classes from "./Input.module.scss";

export const InputOther = ({...props}) => {

    return (
        <label className={classes.input__wrapper}>
            <input
                {...props}
                className={classes.input}/>
        </label>
    );
};