import classes from "./User.module.scss";
import Container from "../../components/container/Container";
import { Modal } from "../../components/modal/Modal";
import TitleBig from "../../components/titleBig/TitleBig";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../context/context";
import Title from "../../components/title/Title";
import TitleSmall from "../../components/titleSmall/TitleSmall";
import TextH4 from "../../components/ui/typographuck/textH4/TextH4";
import {HOST} from "../../constants";
import {InputOther} from "../../components/ui/input/Input-other";
import {Checkbox} from "../../components/ui/checkbox/CheckBox";
import Button from "../../components/ui/buttons/button/Button";
import { EditUser } from "../../components/editUser/EditUser";
import { ModalContext } from "../../context/modal";

import { checkBoxValues } from "../../constants";

type NewUser = {
  login: string;
  password: string;
  privileges: string[];
}

type TUser = NewUser & {
  id: number;
  createdAt: string;
  updatedAt?: string;
}

export const User = () => {
  const {isAuth} = useContext(Context);
  const headers = {
    'Accept': 'text/html',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${isAuth}`
  }

  const [users, setUsers]: [users: TUser[], SetUsers: any] = useState([])
  const [newUser, setNewUser]: [newUser: NewUser, setNewUser: any] = useState({
    login: '',
    password: '',
    privileges: []
  })
  const [editUser, setEditUser]: [editUser: any, setEditUser: any] = useState(
    {
      id: null,
      login: '',
      privileges: [],
    }
  )
  const [errorMessage, setErrorMassage]: [errorMassage: string, setErrorMessage: any] = useState('');
  const { modal, open, close } = useContext(ModalContext)

  const errorEvent = async (message) => {
    setErrorMassage(message)
    await new Promise(resolve => setTimeout(resolve, 5000));
    setErrorMassage('')
  };

  const getUsers = async () => {
    const response = await fetch(`${HOST}/users`, {
      method: 'GET',
      headers: headers
    })
    const data = await response.json();
    setUsers(data)
  };

  const createUser = async event => {
    event.preventDefault()
    if (!newUser.login.trim()) {
      return errorEvent('Введите корректный логин')
    }
    if (!newUser.password.trim()) {
      return errorEvent('Введите корректный пароль')
    }
    try {
      await fetch(`${HOST}/users`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(newUser)
        }
      )
      await getUsers()
    } catch (e: any) {
      errorEvent(e?.message ? e?.message : e).catch(console.error)
    }
  };

  const deleteUser = async id => {
    try {
      await fetch(`${HOST}/users/${id}`, {
          method: 'DELETE',
          headers: headers,
        }
      )
      await getUsers()
    } catch (e: any) {
      errorEvent(e?.message ? e?.message : e).catch(console.error)
    }
  };

  const changePrivileges = ({target: {value, checked}}) => {
    const set = new Set(newUser.privileges)

    if (checked) {
      set.add(value)
    } else {
      set.delete(value)
    }
    setNewUser((prev) => ({...prev, privileges: [...set]}))
  };

  const getPrivileges = privilege => {
    const data = checkBoxValues.find(el => el.key === privilege)
    return data ? data.text : ''
  };

  useEffect(() => {
    getUsers().catch(console.error)
  }, [])

  return (
    <div className={classes.users}>
      <Container>
        <div className={classes.users__title}>
          <TitleBig children="Пользователи"/>
        </div>
        <div className={classes.addUser__wrapper}>
          <Title children="Создание нового пользователя"/>
          <div>
            <form className={classes.addUser} onSubmit={createUser}>
              <div className={classes.addUser__inputWrap}>
                <InputOther
                  placeholder={'Введите логин'}
                  value={newUser.login}
                  onChange={({target: {value}}) => setNewUser((prev) => ({...prev, login: value}))}/>
                <InputOther
                  placeholder={'Введите пароль'}
                  value={newUser.password}
                  onChange={({target: {value}}) => setNewUser((prev) => ({...prev, password: value}))}/>
              </div>
              <div className={classes.addUser__checkBoxWrap}>
                <TitleSmall style={{margin: 0}} children={'привилегии'}/>
                {checkBoxValues.map((obj, index) => (
                  <Checkbox key={index}
                            value={obj.key}
                            label={obj.text}
                            onChange={(event) => changePrivileges(event)}/>
                ))}
              </div>
              <div className={classes.addUser__buttonWrap}>
                <Button type={'submit'} children={'добавить'}/>
              </div>
            </form>
            {errorMessage &&
              <div className={classes.errorWrap}>
                {errorMessage}
              </div>}
          </div>
        </div>
        <div className={classes.resultOfChecking}>
          <Title children="Список пользователей"/>
          <div className={classes.resultOfChecking__content}>
            <div className={classes.table}>
              <div className={classes.table__titles}>
                {["id", "Логин", "Привилегии", "Дата создания"].map((name) =>
                  <TitleSmall key={name} style={{margin: 0, display: "flex", alignItems: "center"}} children={name}/>
                )}
              </div>
              <div>
                {users.length ? users.map((user, index) => (
                  <div key={user.id}
                       className={index % 2 === 0 ? classes.table__row : `${classes.table__row} ${classes.table__row_dark}`}>
                    <div className={classes.table__col}>{user.id}</div>
                    <div className={classes.table__col}>{user.login}</div>
                    <div
                      className={classes.table__col}>{user.privileges && user.privileges.length ? user.privileges.map((privilege: string, index) => (
                      <div className={classes.table__privilege} key={index} children={getPrivileges(privilege)}/>
                    )) : 'Привилегий нет'}</div>
                    <div className={classes.table__col}>{(new Date(user.createdAt)).toISOString()}</div>
                    <div className={classes.table__col}>
                      <div className={classes.table__actions}>
                        <div className={[classes.table__actions_edit, classes.table__action].join(" ")}
                             onClick={() => {
                               setEditUser({...user, password: ''})
                               open()
                             }}>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="494.936px" height="494.936px" viewBox="0 0 494.936 494.936"
                               xmlSpace="preserve">
<g>
	<g>
		<path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157    c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21    s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741    c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"/>
    <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069    c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963    c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692    C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107    l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005    c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"/>
	</g>
</g>
</svg>
                        </div>
                        <div className={[classes.table__actions_delete, classes.table__action].join(" ")}
                             onClick={() => {
                               deleteUser(user.id)
                             }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                              d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/>
                          </svg>
                        </div>
                      </div>

                    </div>
                  </div>
                )) : <TextH4 children="Пользователей нет"/>}
              </div>
            </div>
          </div>
        </div>
      </Container>
      {modal && <Modal title={"Редактирование пользователя"}>
        <EditUser user={editUser} setEditUser={setEditUser} headers={headers} getUsers={getUsers}></EditUser>
      </Modal>}
    </div>
  );
}