import React, {useContext, useEffect, useState} from "react";
import classes from "./RequestAddress.module.scss";
import TitleSmall from "../titleSmall/TitleSmall";
import {InputButton} from "../ui/input/Input-button";
import Select from "../ui/select/Select";
import {Context} from "../../context/context";
import {HOST} from "../../constants";

const RequestAddress = ({hostnameState, regionState, regionsState, onSetOptions}) => {

  const {isAuth} = useContext(Context);
  const defaultOptions: {key: string; value: string;}[] = []
  const [options, setOptions] = useState(defaultOptions);

  const [hostname, setHostname] = hostnameState
  const [, setRegion] = regionState;
  const [, setRegions] = regionsState;

  let requested = false;

  useEffect(() => {
      if((options && options.length > 1) || !isAuth || requested) {
        return;
      }

      requested = true;

      const regionsResponse = fetch(`${HOST}/regions`, {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${isAuth}`
        }
      }).then(async (regionsResponse) => {
        const regionsData = await regionsResponse.json();

        setRegions(regionsData);

        const optionsData: {key: string, value: any}[] = [];
        for(const [key, value] of Object.entries(regionsData)) {
          optionsData.push({key, value})
        }
        setOptions(optionsData);
        onSetOptions([{key: '0', value: 'Все регионы'}, ...optionsData]);
      });

  }, [])


  return (
    <div className={classes.requestAddress}>
      <div className={classes.requestAddress__address}>
        <TitleSmall children="Сайт"/>
        <div className={classes.requestAddress__input}>
          <InputButton
            placeholder="Введите адрес сайта"
            isDisabled={!(hostname?.trim?.())}
            value={hostname}
            setValue={setHostname}
            colorButton="#F8F8F8"
            onChange={({target: {value}}) => setHostname(value)}
          />
        </div>
      </div>
      <div className={classes.requestAddress__region}>
        <TitleSmall children="Регион"/>
        <Select isDefaultValue={false} defaultValue="Выберите регион" options={options}
                onChange={({target: {value}}) => setRegion(value)}/>
      </div>
    </div>
  );
};

export default RequestAddress;
