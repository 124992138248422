import React, {useContext} from "react";
import classes from "./ButtonOut.module.scss";
import TextH4 from "../../typographuck/textH4/TextH4";
import {Context} from "../../../../context/context";
import {useNavigate} from "react-router-dom";

const ButtonOut = ({children = '', ...props}) => {
  const {setIsAuth} = useContext(Context);
  const navigate = useNavigate();

  const logOut = (/** @type {{ preventDefault: () => void; }} */ event) => {
    event.preventDefault();
    setIsAuth('')
    localStorage.removeItem('access_token');
    navigate('/login')
  }
  return (
    <button {...props} className={classes.buttonOut} onClick={logOut}>
      <div className={classes.buttonOut__text}>
        <TextH4 children="Выход"/>
      </div>
      <div className={classes.buttonOut__icon}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7"
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
    </button>
  );
};

export default ButtonOut;