import classes from "./Checkbox.module.scss";

export const Checkbox = ({label= '',value, ...props}) => {

  return (
    <label className={classes.checkbox__wrapper}>
      <input
        type={"checkbox"}
        value={value}
        {...props}
        className={classes.checkbox}/>
      <div className={classes.checkbox__wrapper_text}>{label}</div>
    </label>
  );
};