import React, {Dispatch} from 'react';
import classes from "./Input.module.scss";
import ButtonClose from "../buttons/buttonClose/ButtonClose";

type Params = {
  isDisabled: boolean;
  placeholder: string;
  colorButton: string;
  children?: string;
  setValue: (a: string) => void | Dispatch<any> | null;
  onChange?: any;
  [props: string]: any;
}

export const InputButton = ({isDisabled = false, placeholder = '', colorButton = '', children = '', setValue = ()=>{}, ...props}: Params) => {
  return (
    <label className={classes.input__wrapper}>
      <input
        {...props}
        className={classes.input}
        placeholder={placeholder}/>
      {!isDisabled && <div className={classes.input__button}>
        <ButtonClose onClick={() => setValue('')} buttonColor={colorButton} children={children}/>
      </div>}
    </label>
  );
//@ts-ignore
};