import React, {useState} from "react";
import classes from "./Requests.module.scss";
import TitleSmall from "../titleSmall/TitleSmall";
import {InputButton} from "../ui/input/Input-button";
import ButtonClose from "../ui/buttons/buttonClose/ButtonClose";
import Button from "../ui/buttons/button/Button";
import {RequestSearchItem} from "../requestSearchItem/RequestSearchItem";
import TextP from "../ui/typographuck/textP/TextP";


export const Requests = ({searchState, sendCheckOne}) => {
  const [textRequest, setTextRequest] = useState('');
  const [searchArray, setSearchArray] = searchState;

  const addRequest = (value: string | string[]) => {
    const set = new Set(searchArray);

    Array.isArray(value) ? value.forEach(value => set.add(value)) : set.add(value);

    setSearchArray([...set])

    setTextRequest('')
  }

  const onInput = async ({nativeEvent: {inputType}, target: {value}}) => {

    if (inputType !== 'insertFromPaste') {
      return setTextRequest(value);
    }

    try {
      const past = await navigator.clipboard.readText();
      const values: string[] = [];
      past.split('\n').forEach(row => row.split('\t').forEach(col => col.trim() && values.push(col.trim())))
      addRequest(values);
    } catch (e) {
      alert('Для того, чтобы вставлять данные, разрешите работу с буфером обмена для этого сайта.')
    }
  }

  const onKeyPress = ({target: {value}, key}) => key === 'Enter' && value.trim() && addRequest(value.trim());

  return (
    <div className={classes.requests__wrapper}>
      <TitleSmall children="Запросы"/>
      <div className={classes.requests}>
        <div className={classes.requests__input}>
          <InputButton
            placeholder="Введите ключевые слова и предложения"
            isDisabled={!textRequest?.trim?.()}
            setValue={setTextRequest}
            colorButton="#F8F8F8"
            value={textRequest}
            onInput={onInput}
            onKeyPress={onKeyPress}
          />
          <div className={classes.requests__list}>
            {searchArray.map((text, index) =>
              <RequestSearchItem
                key={index}
                children={text}
                onClick={({target: {parentElement: {parentElement: {innerText}}}}) => setSearchArray(searchArray.filter(i => i !== innerText))}
              />)}
          </div>
        </div>
        <div className={classes.requests__buttons}>
          <div className={searchArray.length ? classes.button : classes.button + ' ' + classes.disabled}
               onClick={() => setSearchArray([])}>
            <ButtonClose buttonColor="#000000"/>
            <TextP children="Сбросить фильтр"/>
          </div>
          <div className={classes.button} onClick={sendCheckOne}>
            <Button children="Старт" disabled={!searchArray.length && true}/>
          </div>
        </div>
      </div>
    </div>
  );
};