import React, {useContext} from "react";
import {useLocation, Route, Routes, useNavigate} from "react-router-dom";
import Login from "./pages/login/Login";
import {Context} from "./context/context";
import {Home} from "./pages/home/Home";
import {User} from "./pages/user/User";

export const AppRoutes = () => {

  const navigate = useNavigate();
  const {isAuth} = useContext(Context)
  const {pathname} = useLocation();

  if (!isAuth && pathname !== '/login') {

    navigate("/login");
    return (
      <Routes>
        <Route path="*" element={<Login/>}/>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/user" element={<User/>}/>
      <Route path="/login" element={<Login/>}/>
    </Routes>
  );
};