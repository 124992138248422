import React from "react";
import classes from "./Loader.module.scss";
import TextP from "../typographuck/textP/TextP";

const Loader = ({process = 0, found, passed,  ...props}) => {

  return (
      found || process  >= 100 ?
        <div {...props} className={classes.loaded}>
          {passed && <img src="/images/check-circle.svg" alt="check"/>}
            <div className={classes.loader__text}>
              <TextP children={passed ? 'Не обнаружен': (found ? 'Обнаружен' : 'Не найдено')}/>
            </div>
        </div>
        :
        <div {...props} className={classes.loader}>
          <img src="/images/loader.svg" alt="loader"/>
          <div className={classes.loader__text}>
            <TextP children={`Поиск ${process}%`}/>
          </div>
        </div>

    );
};

export default Loader;
