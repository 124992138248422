import React from "react";
import classes from "./TitleBig.module.scss";
import TextH2 from "../ui/typographuck/textH2/TextH2";

const TitleBig = ({children = '', ...props}) => {
    return (
        <div {...props} className={classes.titleBig}>
            <TextH2 children={children} />
        </div>
    );
};

export default TitleBig;