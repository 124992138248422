import ReactDOM from 'react-dom/client';
import React, {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import Header from "./components/header/Header";
import {AppRoutes} from "./AppRoutes";
import "./styles/styles.scss"
import {Context} from "./context/context";
import { ModalState } from "./context/modal";

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

const App = () => {
  const [isAuth, setIsAuth] = useState('')

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');

    if(access_token) {
      setIsAuth(access_token)
    }
  }, [])

  return (
    <Context.Provider value={{
      isAuth,
      setIsAuth
    }}>
      <ModalState>
        <BrowserRouter>
          <Header/>
          <AppRoutes/>
        </BrowserRouter>
      </ModalState>
    </Context.Provider>
  );
};

root.render(
    <App />
);
