import classes from "./Pagination.module.scss";
import { useMemo } from "react";
const DOTS = '...';

const pageSizes = [10, 25, 50, 100 ];

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {

      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export const Pagination = ({source = '',currentPage = 1, totalCount= 1, pageSize= 10, onPageChange, onPageSizeChange, siblingCount = 1} ) => {

  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize }) || [];

  if (currentPage === 0) {
    return null;
  }
  const onNext = () => {
    if (currentPage < Math.ceil(totalCount / pageSize)) {
      onPageChange(currentPage + 1);
    }
  };
  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };
  const changePageSize = (event) => {
    onPageSizeChange(event.target.value)
  }
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div data-type="pagination" data-source={source}>
      <ul className={classes.paginationContainer}>
        <li
          className={classes.paginationArrow + (currentPage === 1 ? ' ' + classes.disabled: '') }
          onClick={onPrevious}
        >
          <div className={ classes.paginationArrow__left } />
        </li>
        {paginationRange.map((pageNumber, index) => {

          if (pageNumber === DOTS) {
            return <li key={index} className={ classes.paginationItem__dots}>&#8230;</li>;
          }
          return (
            <li key={index}
              className={(pageNumber === currentPage ? classes.paginationItem__selected : classes.paginationItem)}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classes.paginationArrow + (currentPage === lastPage ? ' ' + classes.disabled: '' )}
          onClick={onNext}
        >
          <div className={ classes.paginationArrow__right } />
        </li>
      </ul>
      <select className={classes.paginationPageSize} onChange={changePageSize} defaultValue={pageSize}>
        {pageSizes.map((element, index) => (
          <option className={classes.paginationPageSize__option} value={element} key={index}>{element}</option>
        ))}
      </select>
    </div>
  )
}