import {io} from "socket.io-client";

export const HOST = document.location.hostname === 'localhost' ? '//localhost:3001' : '';

//export const HOST = document.location.hostname === 'localhost' ? 'https://snippet-filter-dev.4px.ru' : ''

export const socket = io(HOST, {
  transports: ['websocket']
});

export const checkBoxValues = [
  {key: "CAN_CHECK", text: 'Возможность проверки сниппета'},
  {key: "CAN_CRUD_USER", text: 'Возможность создавать, редактировать и удалять пользователей'}
]
