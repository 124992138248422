import React from 'react';
import classes from "./RequestSearchItem.module.scss";
import ButtonClose from "../ui/buttons/buttonClose/ButtonClose";

export const RequestSearchItem = ({children, ...props}) => {
    return (
        <div className={classes.requestSearchItem} {...props}>
            {children}
            <ButtonClose buttonColor="#6F6F6F"/>
        </div>
    );
};