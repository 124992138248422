import React from "react";
import classes from "./TitleSmall.module.scss";
import TextP from "../ui/typographuck/textP/TextP";

const TitleSmall = ({children = '', ...props}) => {
    return (
        <div {...props} className={classes.titleSmall}>
            <TextP children={children} />
        </div>
    );
};

export default TitleSmall;