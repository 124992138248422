import React from "react";
import classes from "./TextH4.module.scss";

const TextH4 = ({children = '', ...props}) => {
    return (
        <h4 {...props} className={classes.textH4}>
            {children}
        </h4>
    );
};

export default TextH4;