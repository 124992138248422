import React from "react";
import classes from "./ButtonDownload.module.scss";
import TextP from "../../typographuck/textP/TextP";

const ButtonDownload = ({children = '', ...props}) => {
    return (
        <button {...props} className={classes.buttonDownload}>
            <img src="/images/download.svg" alt="download"/>
            <div className={classes.buttonDownload__text}>
                <TextP children={children}/>
            </div>
        </button>
    );
};

export default ButtonDownload;