import React, { useContext, useState } from "react";
import classes from './EditUser.module.scss'
import { InputOther } from "../ui/input/Input-other";
import TitleSmall from "../titleSmall/TitleSmall";
import { Checkbox } from "../ui/checkbox/CheckBox";
import Button from "../ui/buttons/button/Button";
import login from "../../pages/login/Login";
import { HOST } from "../../constants";
import { ModalContext } from "../../context/modal";
import { checkBoxValues } from "../../constants";

export const EditUser = ({user, setEditUser, headers, getUsers}) => {

  const {close} = useContext(ModalContext)

  const changePrivileges = ({target: {value, checked}}) => {
    const set = new Set(user.privileges)

    if (checked) {
      // @ts-ignore
      set.add(value)
    } else {
      // @ts-ignore
      set.delete(value)
    }
    setEditUser((prev) => ({...prev, privileges: [...set]}))
  }

  const [errorMessage, setErrorMassage]: [errorMassage: string, setErrorMessage: any] = useState('');

  const errorEvent = async (message) => {
    setErrorMassage(message)
    await new Promise(resolve => setTimeout(resolve, 5000));
    setErrorMassage('')
  };

  const saveEditUser = async (event) => {
    event.preventDefault()
    console.log('тест');
    const data = {}
    for (const key in user) {
      if (key === 'id') { continue }
      if (key === 'login' && !user.login.trim()) { continue }
      if (key === 'password' && !user.password.trim()) { continue }
      if (key !== 'createdAt' && key !== 'updatedAt') {
        data[key] = user[key]
      }
    }
    try {
      await fetch(`${HOST}/users/${user.id}`, {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify(user)
        }
      )
      await getUsers()
      close()
    } catch (e: any) {
      errorEvent(e?.message ? e?.message : e).catch(console.error)
    }
  }


  return (
    <div>
      <form className={classes.editUser} onSubmit={saveEditUser}>
        <div className={classes.editUser__inputWrap}>
          <InputOther
            placeholder={'Введите логин'}
            value={user.login}
            onChange={({target: {value}}) => setEditUser((prev) => ({...prev, login: value}))}/>
          <InputOther
            placeholder={'Введите пароль'}
            value={user.password}
            onChange={({target: {value}}) => setEditUser((prev) => ({...prev, password: value}))}/>
          <span className={classes.editUser__hint}>*Для изменения пароля введите новый пароль. Если пароль менять не нужно оставьте поле пустым</span>
        </div>
        <div className={classes.editUser__checkBoxWrap}>
          <TitleSmall style={{margin: 0}} children={'привилегии'}/>
          {checkBoxValues.map((obj, index) => (
            <Checkbox key={index}
                      value={obj.key}
                      label={obj.text}
                      checked={user.privileges.includes(obj.key)}
                      onChange={(event) => changePrivileges(event)}/>
          ))}
        </div>
        <div className={classes.addUser__buttonWrap}>
          <Button type={'submit'} children={'сохранить'}/>
        </div>
      </form>
      {errorMessage &&
        <div className={classes.errorWrap}>
          {errorMessage}
        </div>}
    </div>
  )
}