import React from "react";
import classes from "./ButtonClose.module.scss";

const ButtonClose = ({children = '', buttonColor = '', ...props}) => {
    return (
        <button
            {...props}
            className={classes.buttonClose}
            style={{color: `${buttonColor}`}}
        >
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0719 5.53641L5.90527 14.7031" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.90527 5.53641L15.0719 14.7031" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className={classes.buttonClose__frame}></div>
        </button>
    );
};

export default ButtonClose;