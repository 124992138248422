import React, {useContext} from "react";
import classes from "./Header.module.scss";
import Container from "../container/Container";
import {Link} from "react-router-dom";
import ButtonOut from "../ui/buttons/buttonOut/ButtonOut";
import {Context} from "../../context/context";

const Header = () => {
    const {isAuth} = useContext(Context)

    return (
        <div className={classes.header}>
            <Container>
                <div className={classes.header__content}>
                    <Link to="/" className={classes.header__logo}>
                        <img src="/images/logo.svg" alt="Logo"/>
                    </Link>
                    {isAuth && <ButtonOut/>}
                </div>
            </Container>
        </div>
    );
};

export default Header;