import React from "react";
import classes from "./Title.module.scss";
import TextH3 from "../ui/typographuck/textH3/TextH3";

const Title = ({children = '', ...props}) => {
    return (
        <div {...props} className={classes.title}>
            <TextH3 children={children} />
        </div>
    );
};

export default Title;