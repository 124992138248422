import React from "react";
import classes from "./TextP.module.scss";

const TextP = ({children = '', ...props}) => {
    return (
        <p {...props} className={classes.textP}>
            {children}
        </p>
    );
};

export default TextP;