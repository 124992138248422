import React from "react";
import classes from "../textH3/TextH3.module.scss";

const TextH3 = ({children = '', ...props}) => {
    return (
        <h3 {...props} className={classes.textH3}>
            {children}
        </h3>
    );
};

export default TextH3;